<template>
  <transition name="fade">
    <div class="modal-mask animated">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Unidades</h4>
            <button type="button" class="close" data-dismiss="modal" @click="$emit('closeUnidades')">&times;</button>
          </div>
          <div class="modal-body">
            <div class="row" v-if="permissoes['Unidades'] > 1">
              <div class="col">
                <router-link :to="{ name: 'EmpresasCadastroUnidade', query: { idEmpresa: empresa.id } }">
                  <button type="button" class="btn btn-primary float-right btn-hover btn-dark-color">CADASTRAR</button>
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <MyTable
                  :data="empresa.unidades"
                  :columns="columns"
                  :itemsPerPage="10">
                  <template #column="{item, column}">
                    <span v-if="column.field == 'actions'" class="d-flex">
                      <i class="fas fa-edit btn  btn-warning mr-1" title="Editar empresa" @click="editarItem(item.id)" v-if="permissoes['Unidades'] > 1"></i>
                      <i class="fas fa-trash-alt btn btn-danger" title="Deletar empresa" @click="deletarItem(item)" v-if="permissoes['Unidades'] > 2"></i>
                    </span>
                    <span v-else>
                      {{item[column.field]}}
                    </span>
                  </template>
                </MyTable>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
         
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import MyTable from './../../uicomponents/MyTable'
import empresaRepository from './../../services/api/empresaRepository'
export default {
  components: {
    MyTable
  },
  props: ['propEmpresa'],
  created () {
    this.empresa = this.propEmpresa
    this.permissoes = this.$store.getters.permissoes['Empresas'] ?? []
  },
  data () {
    return {
      empresa: {},
      columns: [
        {
          label: 'Nome',
          field: 'nome_fantasia',
          sortable: 'nome_fantasia'
        },
        {
          label: 'Razão Social',
          field: 'razao_social',
          sortable: 'razao_social'
        },
        {
          label: 'Cnpj',
          field: 'cnpj',
          sortable: 'cnpj'
        },
        {
          label: 'Endereço',
          field: 'endereco_completo',
          sortable: 'endereco_completo'
        },
        {
          label: 'Telefone',
          field: 'telefone',
          sortable: ''
        },
        {
          label: 'Área de Atividade',
          field: 'area_atividade',
          sortable: ''
        },
        {
          label: 'Ações',
          field: 'actions',
          sortable: ''
        }
      ],
      permissoes: []
    }
  },
  methods: {
    cadastrarUnidade (id) {
      this.$router.push({ name: 'EmpresasCadastroUnidade', query: { idEmpresa: id } })
    },
    editarItem (id) {
      this.$router.push({ name: 'EmpresasCadastroUnidade', query: { id: id } })
    },
    deletarItem (unidade) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover unidade',
        text: `Deseja deletar a unidade ${unidade.razao_social}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarUnidade(unidade)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Unidade removida com sucesso!'
          }).then(() => {
            _this.fetch()
          })
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarUnidade (empresa) {
      this.isLoading = true
      empresaRepository.delete(empresa.id).then((response) => {
        this.isLoading = false
        return response.data['success']
      }).catch(() => {
        this.isLoading = false
        return false
      })
    }
  }
}
</script>

<style>
/*
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .1s ease;
  }
  
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .1s ease;
    font-family: Helvetica, Arial, sans-serif;
  }
  
  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }
  
  
  .modal-default-button {
    float: right;
  }
  
  .modal-enter, .modal-leave {
    opacity: 0;
  }
  
  .modal-enter .modal-container,
  .modal-leave .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .modal-header > .modal-title {
    float: left;
  }

  /* TRANSICTION EFFECTS */
  /*
  .fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
  }
  .fade-enter, .fade-leave-to  .fade-leave-active em versões anteriores a 2.1.8  {
    opacity: 0;
  }

  .bounce-enter-active {
    animation: bounce-in .1s;
  }
  .bounce-leave-active {
    animation: bounce-in .1s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

.modal-dialog {
  width: 90%;
}
.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

@media (min-width: 576px)
{
.modal-dialog {
  max-width: 80%;    
  }
}

.modal-body {
  height: 70vh;
}
*/
</style>