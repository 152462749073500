<template>
  <div class="container-fluid card-body">
    <Load :isLoading="isLoading" />
    <EmpresasUnidades v-if="showUnidades" @closeUnidades="showUnidades = false" :propEmpresa="empresaSelecionada"/>
    <div class="row">
      <div class="col">
        <h1>Grupo de Empresas</h1>
      </div>
      <div class="col">
        <router-link to="empresas/cadastro">
          <button type="button" class="btn btn-primary float-right btn-hover btn-dark-color" v-if="permissoes['Empresas'] > 1">CADASTRAR</button>
        </router-link>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <MyTable
          :searchable="true"
          :data="empresas"
          :columns="columns"
          :itemsPerPage="10">
          <template #column="{item, column}">
            <span v-if="column.field == 'actions'" class="float-right d-flex">
              <i class="fas fa-home mr-1 btn btn-info" title="Ver unidades" @click="verUnidades(item)" v-if="permissoes['Unidades'] > 0"></i>
              <i class="fas fa-edit mr-1 btn btn-warning " title="Editar empresa" @click="editarItem(item.id)" v-if="permissoes['Empresas'] > 1"></i>
              <i class="fas fa-trash-alt mr-1 btn btn-danger" title="Deletar empresa" @click="deletarItem(item)" v-if="permissoes['Empresas'] > 2"></i>
            </span>
            <span v-else>
              {{item[column.field]}}
            </span>
          </template>
        </MyTable>
      </div>
    </div>
  </div>
</template>

<script>
import MyTable from './../../uicomponents/MyTable'
import EmpresasUnidades from './EmpresasUnidades'
import empresaRepository from './../../services/api/empresaRepository'
export default {
  name: 'Empresas',
  components: {
    MyTable,
    EmpresasUnidades
  },
  data(){
    return {
      isLoading: false,
      showUnidades: false,
      columns: [
        {
          label: 'Nome',
          field: 'nome_fantasia',
          sortable: 'nome_fantasia'
        },
        {
          label: 'Razão Social',
          field: 'razao_social',
          sortable: 'razao_social'
        },
        {
          label: 'Cnpj',
          field: 'cnpj',
          sortable: 'cnpj'
        },
        {
          label: 'Endereço',
          field: 'endereco_completo',
          sortable: 'endereco_completo'
        },
        {
          label: 'Telefone',
          field: 'telefone',
          sortable: ''
        },
        {
          label: 'Área de Atividade',
          field: 'area_atividade',
          sortable: ''
        },
        {
          label: 'Ações',
          field: 'actions',
          sortable: ''
        }
      ],
      empresas: [],
      empresaSelecionada: {},
      permissoes: []
    }
  },
  created () {
    this.fetch()
    this.permissoes = this.$store.getters.permissoes['Empresas'] ?? []
  },
  methods: {
    verUnidades (empresa) {
      this.empresaSelecionada = empresa
      this.showUnidades = true
    },
    editarItem (id) {
      this.$router.push({ name: 'EmpresasCadastro', query: { id: id } })
    },
    deletarItem (empresa) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover empresa',
        text: `Deseja deletar a empresa ${empresa.razao_social}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarEmpresa(empresa)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Empresa removida com sucesso!'
          }).then(() => {
            _this.fetch()
          })
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarEmpresa (empresa) {
      this.isLoading = true
      empresaRepository.delete(empresa.id).then((response) => {
        this.isLoading = false
        return response.data['success']
      }).catch(() => {
        this.isLoading = false
        return false
      })
    },
    fetch () {
      this.isLoading = true
      empresaRepository.listarEmpresas().then(response => {
        this.empresas = response.data['data']
        this.isLoading = false
      }).catch( () => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style>

</style>